import React, { Component } from "react"
import axios from "axios"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"

const API_PATH = "/api-mail.php"
const ValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Zawartość pola jest zbyt krótka")
    .max(50, "Zawartość pola jest zbyt długa")
    .required("Pole wymagane"),
  email: Yup.string()
    .min(2, "Zawartość pola jest zbyt krótka")
    .max(50, "Zawartość pola jest zbyt długa")
    .email("Nieprawidłowy format adresu e-mail")
    .required("Pole wymagane"),
  phone: Yup.string()
    .min(5, "Zawartość pola jest zbyt krótka")
    .max(15, "Zawartość pola jest zbyt długa")
    .required("Pole wymagane"),
  privacy: Yup.bool().oneOf([true], "Wymagana zgoda."),
  privacy2: Yup.bool().oneOf([true], "Wymagana zgoda."),
})

export default class contactForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      email: "",
      phone: "",
      mailSent: false,
      error: null,
    }
  }
  handleFormSubmit = e => {
    // e.preventDefault()
    axios({
      method: "POST",
      url: `${API_PATH}`,
      headers: { "content-type": "application/json" },
      data: e,
    })
      .then(result => {
        this.setState({
          mailSent: result.data.sent,
          error: result.data.message,
        })
        window.dataLayer.push({
          event: "conversion",
        })
      })
      .catch(error => this.setState({ error: error.message }))
  }

  render() {
    if (this.state.mailSent === false) {
      return (
        <Formik
          initialValues={{
            name: "",
            email: "",
            phone: "",
            privacy: false,
            privacy2: false
          }}
          validationSchema={ValidationSchema}
          onSubmit={values => {
            this.handleFormSubmit(values)
          }}
        >
          {({ isSubmitting }) => (
            <Form className="lead-form">
              <h2>Rejestracja:</h2>
              <p className="bold-gradient">
                Więcej informacji na temat udzielanych świadczeń zdrowotnych, stosowanych metod diagnostycznych lub terapeutycznych oraz ich jakości i bezpieczeństwa podajemy na wniosek pacjenta: 
              </p>
              <div className="form-fields-wrap">
                <label htmlFor="name">
                  Imię i nazwisko:{" "}
                  <ErrorMessage
                    className="error-msg"
                    name="name"
                    component="span"
                  />
                </label>
                <Field type="text" name="name" />

                <label htmlFor="email">
                  Adres e-mail:{" "}
                  <ErrorMessage
                    className="error-msg"
                    name="email"
                    component="span"
                  />
                </label>
                <Field type="email" name="email" />

                <label htmlFor="phone">
                  Numer telefonu:{" "}
                  <ErrorMessage
                    className="error-msg"
                    name="phone"
                    component="span"
                  />
                </label>
                <Field type="phone" name="phone" />
              </div>
              <button
                className="btn-accent btn"
                type="submit"
                disabled={isSubmitting}
              >
                Wyślij
              </button>
              <div className="privacy-policies">
                <div>
                  <label>
                    <Field
                      className="checkbox"
                      type="checkbox"
                      id="privacy"
                      name="privacy"
                    />
                    Wyrażam zgodę na przetwarzanie moich danych osobowych przez spółkę Gabinetkonopny.pl Sp. z o.o. (Administrator Danych Osobowych) wskazanych w powyższym formularzu celem kontaktu w sprawach profilaktyki lub różnych form informacji dotyczących wykorzystywania konopi w celach leczniczych (Szczegółowe zasady przetwarzania danych osobowych zawiera <a href="/polityka-prywatnosci.pdf" target="_blank">Polityka prywatności</a>. 
Udzieloną zgodę można cofnąć wysyłając e-mail na adres: <a href="mailto:kontakt@gabinetkonopny.pl">kontakt@gabinetkonopny.pl</a>.
                    <ErrorMessage
                      className="error-msg"
                      name="privacy"
                      component="span"
                    />
                  </label>
                  <label>
                    <Field
                      className="checkbox"
                      type="checkbox"
                      id="privacy2"
                      name="privacy2"
                    />
                    Wyrażam zgodę na przesyłanie mi informacji o realizowanej profilaktyce lub wykorzystaniu konopi do celów leczniczych ma ww. adres e-mail lub numer telefonu.
                    <ErrorMessage
                      className="error-msg"
                      name="privacy2"
                      component="span"
                    />
                  </label>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )
    } else {
      setTimeout(function(){
        window.location.href = 'https://www.gabinetkonopny.pl/e-gabinet-konopny-online/';
      }, 1000);
      return (
        <form className="lead-form message-sent">
          <h3>Dziękujemy!</h3>
          <p>Wiadomość została wysłana.</p>
          <p><a className="btn" href="https://www.gabinetkonopny.pl/e-gabinet-konopny-online/"> Przejdź do rejestracji</a> lub poczekaj na przekierowanie.</p>
        </form>
      )
    }
  }
}
