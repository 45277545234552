import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import "../index.scss";
import { Helmet } from "react-helmet"
import CookieConsent from "react-cookie-consent";
import ContactForm from "../components/contactForm";

// markup
const IndexPage = () => {
  return (
    <main>
      <Helmet>
      <title>Gabinet Konopny</title>
      </Helmet>
      <header>
        <div className="wrap">
          <StaticImage src="../images/logo-gk.png" objectFit="contain"
      objectPosition="0 50%" alt="Gabinet Konopny" placeholder="none" />    
          <div className="header-contact-info">
            Masz pytania? <span>&nbsp;Zadzwoń:</span>
            <a href="tel:500108308">+48 500 108 308</a><br/>
            <a href="tel:500108408">+48 500 108 408</a>
            <a href="mailto:kontakt@gabinetkonopny.pl">kontakt@gabinetkonopny.pl</a>
            </div>
        </div>
      </header>
      <section className="top" id="top">
        <div className="wrap two-col">
          <div className="col">
          <h1>TERAPIA MEDYCZNĄ <span>MARIHUANĄ</span></h1>
          <h2>&bull; DOSTĘP DO WIEDZY &bull; 
          PROMOCJI ZDROWIA I POMOCY ŚWIADCZONEJ PRZEZ <span>PODMIOT LECZNICZY</span></h2>
          <h4>Jesteśmy placówką medyczną zarejestrowaną i wpisaną jako Podmiot Leczniczy do Rejestru Podmiotów Wykonującą Działalność Leczniczą (RPWDL)</h4>
          <h5>Specjalizujemy się w leczeniu bólu</h5>
          </div>
          <div className="col right-col">
              <ContactForm/>
          </div>
        </div>
      </section>
      <section className="sub-top">
        <div className="wrap">
          <div className="col">
          <h3>Sprawdź jak możemy Tobie pomóc:</h3>
          <ul>
            <li>Konsultacja lekarska bez wychodzenia z domu</li>
            <li>Informacje o dostępności medycznej marihuany i sposobie jej przyjmowania</li>
            <li>Wsparcie po wizycie – kontakt z lekarzem</li>
          </ul>
          </div>
          <div className="col">
          <h3>Pomagamy pacjentom w:</h3>
          <ul>
            <li>przewlekłym i neuropatycznym bólu</li>
            <li>łagodzeniu objawów związanych ze stresem i depresją</li>
            <li>spastyczności w przebiegu chorób neurologicznych</li>
            <li>osłabieniu, nudnościach i wymiotach wywołanych chemioterapią</li>
            <li>sprawności pamięci</li>

          </ul>
          </div>
        </div>
      </section>
      <section className="about">
        
        <div className="wrap">
          <div className="two-col">
            <div className="col first-col">
              <h2>Jak przebiega proces?</h2>
              <div className="steps-subtitle-wrap">
                <h3>Poznaj 3 kroki:</h3>
              
              <div className="steps">

                <div className="step">
                  <div className="number">
                    <div className="number-inner">
                    1
                    </div>
                  </div>
                  <div className="description">
                    <h3>Wypełniasz formularz kontaktowy</h3>
                    <p>Pozostawiasz swoje dane, a my w ciągu 8 godzin roboczych kontaktujemy się z Tobą w celu doradztwa, odpowiedzi na wszelkie pytania i pomocy w procesie rejestracji do specjalisty.</p>
                  </div>
                </div>
                 <div className="step">
                  <div className="number">
                    <div className="number-inner">
                    2
                    </div>
                  </div>
                  <div className="description">
                    <h3>Odbywasz e-wizytę lub wizytę stacjonarną w gabinecie</h3>
                    <p>Spotykasz się z wybranym specjalistą, który przeprowadza pełen wywiad lekarski i kwalifikuje do terapii kannabinoidami (medyczna marihuana, CBD).</p>
                  </div>
                </div>
                 <div className="step">
                  <div className="number">
                    <div className="number-inner">
                    3
                    </div>
                  </div>
                  <div className="description">
                    <h3>Jesteś pod opieką lekarza i placówki Gabinetkonopny.pl</h3>
                    <p>Otrzymujesz ciągłe wsparcie i kontakt ze strony naszej placówki i specjalistów, pomoc w realizacji recept oraz dostępność przepisanych preparatów.</p>
                  </div>
                </div>
              </div>
              </div>
            </div>
            <div className="col image-col">
  
            </div>
          </div>
        </div>
      </section>
      <section className="sub-top">
        <div className="wrap">
          <div className="col">Medyczna marihuana w Polsce dostępna jest dla Pacjentów wyłącznie na receptę. Aby zakwalifikować się do terapii konopnej konieczny jest wcześniejszy kontakt z lekarzem specjalistą. W naszym gabinecie jest możliwość wizyty stacjonarnej a także wspieramy pacjentów możliwością konsultacji lekarskich w formie zdalnej. Nasi lekarze posiadają dużą wiedzę i doświadczenie w terapii konopnej, podczas e-wizyty dokonują kwalifikacji chorego do terapii, po czym wysyłają sms-em kod do e-recepty</div>
          <div className="col">Leczenie konopiami medycznymi wykazuje dużą skuteczność i wskazane są przede wszystkim w leczeniu takich objawów jak: przewlekły ból, wzmożone napięcie mięśniowe, nudności, wymioty, bezsenność czy stany lękowe. Stosowane są także, z dobrymi efektami, w wielu przewlekłych schorzeniach onkologicznych, neurologicznych, gastrologicznych, psychiatrycznych, a także leczeniu HIV.</div>
        </div>
      </section>
      <section className="team">
        <div className="wrap">
          <div className="intro">
          <h2>Nasz zespół specjalistów:</h2>
          <p>
            Nasi lekarze wykorzystują terapie z użyciem medycznej marihuany i olejków CBD<br/> wspierając naszych pacjentów w dotychczasowym leczeniu.
          </p>
          </div>
          <div className="doctors-wrap">
            <div className="doctor">
              <StaticImage src="../images/puzon-szczotka.jpg" alt="Gabinet Konopny" placeholder="blurred" /> 
              <div className="description">
              <h3>dr n. med. Katarzyna Puzon-Szczotka</h3>
              <h5>NEUROLOG</h5>
              <p>Leczenie bólu, zaburzeń pamięci np.: choroby Alzheimera, rzadszych zespołów otępiennych, padaczka, stwardnienie rozsiane</p>
              </div>
            </div>
            <div className="doctor">
              <StaticImage src="../images/sugajska.jpg" alt="Gabinet Konopny" placeholder="blurred" /> 
              <div className="description">
              <h3>lek. Anna Sugajska-Wilczyńska</h3>
              <h5>ONKOLOG</h5>
              <p>Leczenie bólu przewlekłego i neuropatycznego. Leczenie chorób nowotworowych oraz objawów towarzyszących – osłabienia, nudności, wymiotów związanych z chemioterapią, zespołu wyniszczenia nowotworowego, zaburzeń apetytu, leczenie bólu, paliatywne</p>
              </div>
            </div>
          </div>
          <div className="doctors-wrap three-col">
            <div className="doctor without-pic">
              <div className="description">
              <h3>dr Natalia Nowak</h3>
              <h5>ANESTEZJOLOG</h5>
              <p>Leczenie bólu w onkologii, leczenie bólu przewlekłego i neuropatycznego, leczenie bólu w niewydolności narządowych i chorobach rzadkich, leczenie paliatywne</p>
              </div>
            </div>
            <div className="doctor without-pic">
              <div className="description">
              <h3>dr Tomasz Lichacz</h3>
              <h5>INTERNISTA – Specjalista chorób wewnętrznych</h5>
              <p>Leczenie bólu przewlekłego w tym neuropatycznego, leczenie objawów towarzyszących w onkologii – osłabienia, nudności, wymioty związane z chemioterapią, zaburzenia apetytu, leczenie paliatywne, leczenie bezsenność i zaburzeń snu. Specjalista w dziedzinie chorób wewnętrznych i medycyny paliatywnej.</p>
              </div>
            </div>
            <div className="doctor without-pic">
              <div className="description">
              <h3>Mgr Żaneta Duda</h3>
              <h5>FARMACEUTKA</h5>
              <p>Wsparcie merytoryczne w kwestii produktów, dawkowania i stosowania produktów konopnych - CBD</p>
              </div>
            </div>
          </div>

          <div className="team-cta">
            <a href="#top">Przejdź do formularza</a>
          </div>
        </div>
      </section>
      <footer className="footer">
        <div className="wrap">
          <div className="col">
            <h3>Kontakt:</h3>
            <p>
            TELEFON:<br/>
            <a href="tel:500108308">+48 500 108 308</a><br/>
            <a href="tel:500108308">+48 500 108 408</a>
            </p>
            <p>
            E-MAIL:<br/>
            <a href="mailto:kontakt@gabinetkonopny.pl">kontakt@gabinetkonopny.pl</a>
            </p>
          </div>
          <div className="col">
            <h3>Gabinet Gdynia (Medycyna Wiczlino)</h3>
ul. Wiczlińska 54<br/>
81-578 Gdynia<br/>
REGON zakładu leczniczego: 38810899800016<br/>
telefon: <a href="tel:500108308">+48 500 108 308</a>


          </div>
          <div className="col">
            <h3>Gabinet Gdańsk (Medica Plus Morena)</h3>
            ul. Stanisława Lema 21<br/>
            80-126 Gdańsk<br/>
            REGON zakładu leczniczego: 38810899800023<br/>
            telefon: <a href="tel:500108408">+48 500 108 408</a>
          </div>
        </div>
        <div className="copy">
          <div className="wrap">
          {new Date().getFullYear()} Copyright&copy; by <a href="/">Gabinetkonopny</a> - designed and coded by <a href="https://odado.pl">Odado</a>
          </div>
        </div>
      </footer>
      <CookieConsent
        location="bottom"
        buttonText="&times;"
        className="cookie-consent"
        cookieName="gabinet-konopny"
        style={{ background: "#9fcdd4", fontSize: "12px", color: "#2d6a86" }}
        buttonStyle={{
          color: "#fff",
          height: "25px",
          width: "25px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "26px",
          background: "#2d6a86",
          borderRadius: "50%",
          position: "absolute",
          top: "-25px",
          right: "-15px",
        }}
        expires={150}
      >
        <div className="wrap">
        <p id="Cookie">
          Strona wykorzystuje pliki cookies w celach analitycznych i reklamowych zgodnie z <a target="blank" href="polityka-prywatnosci.pdf">polityką prywatności</a>. Pliki cookies będą zapisywane w pamięci
          Twojego  zgodnie z ustawieniami Twojej przeglądarki. Jeśli
          nie wyrażasz na to zgody, możesz wyłączyć obsługę plików cookies.
        </p>
        </div>
      </CookieConsent>
    </main>
    
  )
}

export default IndexPage
